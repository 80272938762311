/* Removing the OTP verification for now. Keeping the code commented out for future use. */
import React, { useState } from "react";
import Modal from "../../../Common/Modal";
import { useDispatch } from "react-redux";
import SelectAccountList from "../../../Common/SelectAccountList";
import EligibleCurrenciesList from "../partials/EligibleCurrenciesList";
import { applyForNewAccount } from "../../../../redux/actions/ServiceRequests/applyForNewAccount";
import SuccessMessage from "../../../Common/SuccessMessage";
import HangTightLoading from "../../../Common/HangTightLoading";
import EmptyCurrencyList from "../../../Empty/EmptyCurrencyList";
import { AddNewAccountSkeleton } from "../../../Skeleton";
// import { serviceRequestVerifyOTP } from "../../../../redux/actions/ServiceRequests/serviceRequestVerifyOTP";
// import EnterOTP from "../../../Common/EnterOTP";
import useEligibleCurrencies from "../../../../hooks/useEligibleCurrencies";

const AddAccountModal = ({ crypto = false, children }) => {
  const { currencies, LoadingCurrencies, getClientsAccounts } =
    useEligibleCurrencies();
  const [eligibleCurrencies, setEligibleCurrencies] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // const [requestId, setRequestId] = useState(null);
  const [client, setClient] = useState({});
  const [newCurrency, setNewCurrency] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState("");
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();

  // const [otp, setOtp] = useState(Array(6).fill(""));
  // const [resendDisabled, setResendDisabled] = useState(true);
  // const [counter, setCounter] = useState(60);

  // Function to handle OTP submission
  // const handleOTP = async () => {
  //   setIsLoading(true);
  //   const res = await dispatch(
  //     serviceRequestVerifyOTP({
  //       request_id: requestId,
  //       otp: otp.join(""),
  //     })
  //   );

  //   if (res.success) {
  //     setPage(4);
  //     getClientsAccounts();
  //   } else {
  //     setError(res.error);
  //   }
  //   setIsLoading(false);
  // };

  // Function to resend the OTP
  // const handleResendCode = () => {
  //   setCounter(60);
  //   setResendDisabled(true);
  //   handleRequestForCurrency();
  // };

  // Countdown for OTP resend button
  // useEffect(() => {
  //   if (!isLoading && resendDisabled) {
  //     const timer = setInterval(() => {
  //       setCounter((prevCounter) => {
  //         if (prevCounter === 1) {
  //           setResendDisabled(false);
  //           clearInterval(timer);
  //           return 0;
  //         }
  //         return prevCounter - 1;
  //       });
  //     }, 1000);

  //     return () => clearInterval(timer);
  //   }
  // }, [isLoading, resendDisabled]);

  // Reset modal state on close
  const handleOnCloseModal = () => {
    setIsOpen(false);
    setTimeout(() => {
      setClient({});
      setNewCurrency({});
      setError("");
      setPage(1);
      // setOtp(Array(6).fill(""));
      // setRequestId(null);
    }, 300);
  };

  // Handle account selection
  const handleSelectAccount = () => {
    const accounts =
      client?.eligible_accounts?.filter(
        (account) => account.is_crypto === crypto
      ) || [];
    setEligibleCurrencies(accounts);
    setPage(2);
  };

  // Handle request for new currency account
  const handleRequestForCurrency = async () => {
    if (client && newCurrency) {
      setError("");
      setIsLoading(true);
      const res = await dispatch(
        applyForNewAccount({
          client_id: client.id,
          currency_id: newCurrency.id,
        })
      );

      if (res.success) {
        // setRequestId(res.data.request_id);
        setPage(3);
        // calling this function in here in case of the otp feature commented out
        getClientsAccounts();
        // setCounter(60);
      } else {
        setError(res.error);
      }
      setIsLoading(false);
    }
  };

  // Handle back button navigation
  const handleBackButton = () => {
    if (page === 2) {
      setNewCurrency({});
      setPage(1);
    }
    // else if (page === 3) {
    //   setPage(2);
    // }
    setError("");
  };

  return (
    <>
      <div onClick={() => setIsOpen(true)}>{children}</div>
      <Modal
        isOpen={isOpen}
        handleOnCloseModal={handleOnCloseModal}
        // page !== 4 in case of OTP feature being used
        header={page !== 3 && !isLoading}
        headerTitle="Add new account"
        backButton={page === 2}
        backButtonAction={handleBackButton}
        className="bg-popup-bg w-[432px] max-h-[578px] overflow-scroll hide-scrollbar shadow-xl rounded-3xl"
      >
        {/* Skeleton loader on first page */}
        {isLoading ||
          (LoadingCurrencies && page === 1 && (
            <div className="px-6 max-[360px]:px-4 space-y-5 pb-5">
              <AddNewAccountSkeleton />
            </div>
          ))}

        {/* Account selection on page 1 */}
        {!isLoading &&
          !LoadingCurrencies &&
          page === 1 &&
          currencies.length > 0 && (
            <div className="px-6 max-[360px]:px-4 pb-4">
              <SelectAccountList
                clientsAccounts={currencies}
                CloseAddAcccount={client}
                setCloseAddAccount={setClient}
                handleSelectAccount={handleSelectAccount}
                error={error}
              />
            </div>
          )}

        {/* Currency selection on page 2 */}
        {!isLoading && page === 2 && (
          <div className="px-6 max-[360px]:px-4 pb-4">
            {eligibleCurrencies.length > 0 ? (
              <EligibleCurrenciesList
                eligibleCurrencies={eligibleCurrencies}
                setNewCurrency={setNewCurrency}
                newCurrency={newCurrency}
                handleRequestForCurrency={handleRequestForCurrency}
                error={error}
              />
            ) : (
              <EmptyCurrencyList />
            )}
          </div>
        )}

        {/* OTP entry on page 3 */}
        {/* {page === 3 && !isLoading && (
          <div className="md:px-6 px-3 py-2">
            <EnterOTP
              handleOTP={handleOTP}
              otp={otp}
              setOtp={setOtp}
              error={error}
              setError={setError}
              resendDisabled={resendDisabled}
              counter={counter}
              handleResendCode={handleResendCode}
            />
          </div>
        )} */}

        {/* Success message on page 4 */}
        {/* page === 4 in case of OTP feature being used  */}
        {page === 3 && !isLoading && (
          <div className="md:p-6 px-3 py-3">
            <SuccessMessage
              title="New currency account requested"
              description="You will receive an update within 2-3 working days."
              handleCloseButton={handleOnCloseModal}
            />
          </div>
        )}

        {/* Loading spinner */}
        {isLoading && page !== 1 && (
          <HangTightLoading message="We are placing your request!" />
        )}
      </Modal>
    </>
  );
};

export default AddAccountModal;
